import { createWebHistory, createRouter } from "vue-router";
const TheContainer = () => import("@/container/TheContainer");
import store from "./store.js";
import { h, resolveComponent } from "vue";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: TheContainer,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dashboard",
    component: TheContainer,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        component: () => import("./views/Dashboard"),
      },
    ],
  },
  {
    path: "/user",
    component: TheContainer,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/user/settings",
        component: () => import("./views/user/settings"),
      },
    ],
  },
  {
    path: "/adressen",
    component: TheContainer,
    meta: {
      requiresAuth: true,
    },
    redirect: "/adressen/adressen",
    children: [
      {
        path: "/adressen/adressen",
        component: () => import("./views/adressen/adressen"),
      },
    ],
  },
  {
    path: "/holzlisten",
    component: TheContainer,
    meta: {
      requiresAuth: true,
    },
    redirect: "/holzlisten/overview",
    children: [
      {
        path: "/holzlisten/import_fomea",
        component: () => import("./views/holzlisten/import_fomea"),
        meta: {
          module: "FO",
        },
      },
      {
        path: "/holzlisten/overview",
        component: () => import("./views/holzlisten/listen_ov"),
      },
      {
        path: "/holzlisten/liste/:id",
        component: () => import("./views/holzlisten/liste"),
      },
      {
        path: "/holzlisten/polter/:id",
        component: () => import("./views/holzlisten/polter"),
      },
      {
        path: "/holzlisten/section/:id",
        component: () => import("./views/holzlisten/section"),
      },
      {
        path: "/holzlisten/map",
        component: () => import("./views/holzlisten/map"),
      },
      {
        path: "/holzlisten/pivot",
        component: () => import("./views/holzlisten/pivot"),
      },
      {
        path: "/holzlisten/kontierung",
        component: () => import("./views/holzlisten/kontierung"),
      },
      {
        path: "/holzlisten/kontierung_pivot",
        component: () => import("./views/holzlisten/kontierung_pivot"),
      },
    ],
  },
  {
    path: "/stehenderfassung",
    component: TheContainer,
    meta: {
      requiresAuth: true,
    },
    redirect: "/stehenderfassung/group",
    children: [
      {
        path: "/stehenderfassung/group",
        component: () => import("./views/stehenderfassung/group.vue"),
      },
      {
        path: "/stehenderfassung/entries/:id",
        component: () => import("./views/stehenderfassung/entries.vue"),
      },
      {
        path: "/stehenderfassung/map",
        component: () => import("./views/stehenderfassung/map.vue"),
      },
      {
        path: "/stehenderfassung/import_fomea_stand",
        component: () =>
          import("./views/stehenderfassung/import_fomea_stand.vue"),
        meta: {
          module: "FO",
        },
      },
      {
        path: "/stehenderfassung/import_masser_stand",
        component: () =>
          import("./views/stehenderfassung/import_masser_stand.vue"),
        meta: {
          module: "MA",
        },
      },
    ],
  },
  {
    path: "/xpolver",
    component: TheContainer,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/xpolver/xpolver",
        component: () => import("./views/xpolver/xpolver.vue"),
      },
    ],
  },
  {
    path: "/abrechnungen",
    component: TheContainer,
    meta: {
      requiresAuth: true,
      module: "IN",
    },
    redirect: "/abrechnungen/abrechnungen",
    children: [
      {
        path: "/abrechnungen/abrechnungen",
        component: () => import("./views/abrechnungen/abrechnungen.vue"),
      },
      {
        path: "/abrechnungen/abrechnung/:id",
        name: "abrechnung",
        component: () => import("./views/abrechnungen/abrechnung.vue"),
      },
      {
        path: "/abrechnungen/artikel",
        component: () => import("./views/abrechnungen/artikel.vue"),
      },
      {
        path: "/abrechnungen/preisliste",
        component: () => import("./views/abrechnungen/preisliste.vue"),
      },
    ],
  },
  {
    path: "/abrechnungen_ext",
    component: TheContainer,
    meta: {
      requiresAuth: true,
      module: "ST",
    },
    redirect: "/abrechnungen_ext/abrechnungen",
    children: [
      {
        path: "/abrechnungen_ext/abrechnungen",
        component: () => import("./views/abrechnungen_ext/abrechnungen.vue"),
      },
      {
        path: "/abrechnungen_ext/matching",
        component: () => import("./views/abrechnungen_ext/matching.vue"),
      },
      {
        path: "/abrechnungen_ext/lager",
        component: () => import("./views/abrechnungen_ext/lager.vue"),
      },
    ],
  },
  {
    path: "/stammdaten",
    component: TheContainer,
    redirect: "/stammdaten/holzart",
    children: [
      {
        path: "/stammdaten/holzart",
        component: () => import("./views/stammdaten/holzart"),
      },
      {
        path: "/stammdaten/klasse",
        component: () => import("./views/stammdaten/klasse"),
      },
      {
        path: "/stammdaten/staerkeklasse",
        component: () => import("./views/stammdaten/staerkeklasse"),
      },
      {
        path: "/stammdaten/rabzug",
        component: () => import("./views/stammdaten/rabzug"),
      },
      {
        path: "/stammdaten/einheiten",
        component: () => import("./views/stammdaten/einheiten"),
      },
      {
        path: "/stammdaten/tarife",
        component: () => import("./views/stammdaten/tarife"),
      },
    ],
  },
  {
    path: "/empty",
    name: "empty",
    component: () => import("./components/EmptyPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/pages/404",
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: () => import("./views/pages/Page404.vue"),
      },
      {
        path: "500",
        name: "Page500",
        component: () => import("./views/pages/Page500.vue"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("./views/pages/Login.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("./views/pages/Register.vue"),
      },
      {
        path: "registeronline",
        name: "RegisterOnline",
        component: () => import("./views/pages/RegisterOnline.vue"),
      },
      {
        path: "verifyonline",
        name: "VerifyOnline",
        component: () => import("./views/pages/VerifyOnline.vue"),
      },
      {
        path: "verify/:email/:token",
        name: "Verify",
        component: () => import("./views/pages/Verify.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (to.matched.some((record) => record.meta.module)) {
        if (store.getters.modules.indexOf(to.meta.module) < 0) {
          alert("no access to this module - contact the system administrator");
          next(false);
        } else {
          next();
          return;
        }
      } else {
        next();
        return;
      }
    } else {
      next("/pages/login");
    }
  } else {
    next();
  }
});

export default router;
