import { createClient } from "@supabase/supabase-js";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

function getSupabaseCookieName(supabaseUrl) {
  const urlParts = supabaseUrl.split(".");
  const projectRefId = urlParts[0].split("//")[1]; // Extrahieren des Projektreferenzteils
  console.log(`sb-${projectRefId}-auth-token`);
  return `sb-${projectRefId}-auth-token`;
}

// Token auslesen und dekodieren
let supabaseAuthToken = getCookie(
  getSupabaseCookieName(process.env.VUE_APP_SUPABASE_URL)
);
if (supabaseAuthToken && supabaseAuthToken.startsWith("base64-")) {
  supabaseAuthToken = supabaseAuthToken.replace("base64-", "");
  try {
    supabaseAuthToken = atob(supabaseAuthToken); // Base64 dekodieren
  } catch (e) {
    console.error("Fehler beim Decodieren des Tokens:", e);
  }
}

// Supabase-Client initialisieren
export const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL,
  process.env.VUE_APP_SUPABASE_KEY
);

var supabaseAuthTokenStorage = localStorage.getItem("token");
console.log("supabaseAuthToken gemäss Local Storage", supabaseAuthTokenStorage);

// Session setzen, falls ein Token vorhanden ist
if (supabaseAuthToken || supabaseAuthTokenStorage) {
  try {
    var token = JSON.parse(supabaseAuthToken);
    console.log(token);
    console.log(supabaseAuthTokenStorage);
    var storage_token = JSON.parse(supabaseAuthTokenStorage);
    supabase.auth.setSession({
      access_token: storage_token.access_token,
      refresh_token: storage_token.refresh_token,
    });
  } catch (error) {
    console.error("Fehler beim Setzen der Sitzung:", error);
  }
} else {
  console.log("Kein Authentifizierungstoken gefunden.");
}


export function setSupabaseSession() {
  // Token aus localStorage holen
  const supabaseAuthToken = localStorage.getItem("token");

  if (supabaseAuthToken) {
    try {
      const storageToken = JSON.parse(supabaseAuthToken);
      if (storageToken && storageToken.access_token) {
        // Supabase-Session setzen
        supabase.auth.setSession({
          access_token: storageToken.access_token,
          refresh_token: storageToken.refresh_token
        });
        console.log("Supabase-Session wurde erfolgreich gesetzt.");
      } else {
        console.log("Kein gültiger Authentifizierungstoken gefunden.");
      }
    } catch (error) {
      console.error("Fehler beim Setzen der Sitzung:", error);
    }
  } else {
    console.log("Kein Authentifizierungstoken gefunden.");
  }
}