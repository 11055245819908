import { defineStore } from "pinia";
import { supabase } from "@/service/supabase";

export const useStammdatenStore = defineStore("stammdaten", {
  state: () => ({
    data: {},
    loading: {},
    error: {},
    subscribed: {},
    channels: {},
    lastFetchTime: {},
  }),
  actions: {
    async clear() {
      this.$reset(); 
      localStorage.removeItem("stammdaten");
    },
    async fetchData(tableName, force = false, cacheTimeInSeconds = 60) {
      const now = Date.now();
      const channels = supabase.getChannels();
      const channel = channels.find((c) =>
        c.topic.includes(`realtime:public:${tableName}`)
      );

      if (this.subscribed[tableName] && channel && !force) {
        console.log(
          `[${tableName}] ✋ Abonnement für aktiv, API-Aufruf übersprungen.`
        );
        return;
      }

      if (
        !force &&
        this.lastFetchTime[tableName] &&
        (now - this.lastFetchTime[tableName]) / 1000 < cacheTimeInSeconds &&
        this.data[tableName].length > 0
      ) {
        console.log(
          `[${tableName}] 🕚 Die Daten wurden vor kurzem abgerufen. API-Aufruf wird übersprungen. ${new Date(
            this.lastFetchTime[tableName]
          ).toLocaleString()}`
        );
        return;
      }
      this.loading[tableName] = true;

      try {
        const { data, error } = await supabase.from(tableName).select("*");
        if (error) {
          throw error;
        }
        this.data[tableName] = data ? Array.from(data) : [];
        this.lastFetchTime[tableName] = now;
        console.log(`[${tableName}] 🚀 Daten erfolgreich geladen.`);
      } catch (error) {
        this.error[tableName] = error.message;
        console.error(`[${tableName}] ⛔️ Fehler beim Laden der Daten:`, error);
      } finally {
        this.loading[tableName] = false;
      }
    },

    subscribeToChanges(tableName) {
      const channels = supabase.getChannels();
      const channel = channels.find((c) =>
        c.topic.includes(`realtime:public:${tableName}`)
      );
      if (channel) return; // Abonnement nur einmal setzen

      supabase
        .channel(`public:${tableName}`)
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: tableName },
          (payload) => {
            console.log(`[${tableName}] 🗞️ Change received`, payload);
            this.fetchData(tableName, true);
          }
        )
        .subscribe((status) => {
          console.log(`[${tableName}] 🗞️ Subscription status:`, status);
          if (status === "SUBSCRIBED") {
            this.subscribed[tableName] = true;
            this.channels[tableName] = `public:${tableName}`;
          } else if (status === "ERROR") {
            console.error(`Error subscribing to changes on ${tableName}`);
            this.error[tableName] = "Subscription error";
          }
        });
    },

    unsubscribeFromChanges(tableName) {
      if (this.channels[tableName]) {
        supabase.removeChannel(this.channels[tableName]);
        this.subscribed[tableName] = false;
        delete this.channels[tableName]; // Channel entfernen
      }
    },

    unsubscribeFromAllChannels() {
      supabase.removeAllChannels();
      this.subscribed = {}; // Setzt alle subscribed-Zustände zurück
      this.subscriptionStatus = {}; // Setzt alle Status zurück
      this.channels = {}; // Leert das Channel-Objekt
      console.log("Abgemeldet von allen Channels.");
    },

    async fetchQuery(tableName, query, condition = {}) {
      let supabaseQuery = supabase.from(tableName).select(query);
      this.loading["qry_" && tableName] = true;
      Object.keys(condition).forEach((key) => {
        const value = condition[key];
        console.log(`[Query ${tableName}] 🚀 Bedingung: ${key} = ${value}`);
        if (
          typeof value === "object" &&
          value.operator &&
          value.value !== undefined
        ) {
          switch (value.operator) {
            case "eq":
              supabaseQuery = supabaseQuery.eq(key, value.value);
              break;
            case "lt":
              supabaseQuery = supabaseQuery.lt(key, value.value);
              break;
            case "gt":
              supabaseQuery = supabaseQuery.gt(key, value.value);
              break;
            case "like":
              supabaseQuery = supabaseQuery.like(key, value.value);
              break;
          }
        } else {
          console.log(`[Query ${tableName}] 🚀 Bedingung: ${key} = ${value}`);
          supabaseQuery = supabaseQuery.eq(key, value);
        }
      });

      try {
        const { data, error } = await supabaseQuery;
        if (error) {
          throw error;
        }
        console.log(`[Query ${tableName}] 🚀 Daten erfolgreich geladen.`);
        console.log('error', error)
        return data;
      } catch (error) {
        this.error["qry_" && tableName] = error.message;
        console.error(`[${tableName}] ⛔️ Fehler beim Laden der Daten:`, error);
      } finally {
        this.loading["qry_" && tableName] = false;
      }
    },

    /**
     * Fügt einen Datensatz in eine Tabelle ein
     */
    async upsertData(tableName, data, pk = "id") {
      // Wenn `data` kein Array ist, konvertieren wir es in ein Array
      const dataArray = Array.isArray(data) ? data : [data];

      // Trennen der Daten in Updates (mit PK) und Inserts (ohne PK)
      const updateData = dataArray.filter(
        (record) => record[pk] !== null && record[pk] !== undefined
      );
      const insertData = dataArray.filter(
        (record) => record[pk] === null || record[pk] === undefined
      );

      // Upsert-Daten (für Datensätze mit einer ID)
      const upsertPromise = updateData.length
        ? supabase
            .from(tableName)
            .upsert(updateData, {
              onConflict: [pk],
            })
            .select("*")
        : Promise.resolve({ data: [] }); // Wenn kein Update-Datensatz vorhanden, leeres Promise

      // Insert-Daten (für Datensätze ohne eine ID)
      const insertPromise = insertData.length
        ? supabase.from(tableName).insert(insertData).select("*")
        : Promise.resolve({ data: [] }); // Wenn kein Insert-Datensatz vorhanden, leeres Promise

      // Beide Promises abwarten und die Ergebnisse zusammenführen
      return Promise.all([upsertPromise, insertPromise])
        .then(([upsertResult, insertResult]) => {
          const upsertedData = [...upsertResult.data, ...insertResult.data];

          // Daten im Store aktualisieren
          var storedata = this.data[tableName] || [];
          upsertedData.forEach((upsertedRecord) => {
            const index = storedata.findIndex(
              (record) => record[pk] === upsertedRecord[pk]
            );
            index !== -1
              ? storedata.splice(index, 1, { ...upsertedRecord })
              : storedata.push({ ...upsertedRecord });

            console.log(
              `[${tableName}] ✅ Daten erfolgreich upserted/inserted:`,
              upsertedRecord[pk]
            );
          });

          return upsertedData; // Rückgabe der eingefügten/aktualisierten Daten
        })
        .catch((error) => {
          console.error(`[${tableName}] ⛔️ Fehler beim Upsert/Insert:`, error);
          throw error;
        });
    },

    /**
     * Löscht einen Datensatz aus einer Tabelle
     */
    deleteData(tableName, id, pk = "id") {
      return supabase
        .from(tableName)
        .delete()
        .eq(pk, id)
        .then(({ error }) => {
          if (error) {
            throw error;
          }
          console.log(`[${tableName}] ✅ Datensatz erfolgreich gelöscht.`);
          var storedata = this.data[tableName];
          if (!storedata) return false;
          const index = storedata.findIndex((data) => data[pk] === id);
          index !== -1
            ? storedata.splice(index, 1)
            : console.error(
                `[${tableName}] ⛔️ Kein Eintrag mit dieser ID ${id} gefunden`
              );

          return true;
        })
        .catch((error) => {
          console.error(`[${tableName}] ⛔️ Fehler beim Löschen:`, error);
          throw error;
        });
    },
    deleteMultipleData(tableName, filters = [], pk = "id") {
      // Start der Supabase-Abfrage
      let query = supabase.from(tableName).delete();
    
      // Anwenden der Filter, falls vorhanden
      filters.forEach((filter) => {
        const { method, column, operator, value } = filter;
    
        // Beispiel: .eq('column', value) oder .not('column', 'in', value)
        if (method === 'eq') {
          query = query.eq(column, value);
        } else if (method === 'not') {
          query = query.not(column, operator, value);
        } else if (method === 'in') {
          query = query.in(column, value);
        }
        // Hier können weitere Methoden wie .lt(), .gt() usw. hinzugefügt werden
      });
    
      // Ausführen der Abfrage
      return query
        .then(({ error }) => {
          if (error) {
            throw error;
          }
    
          console.log(`[${tableName}] ✅ Datensätze erfolgreich gelöscht.`);
    
          // Optional: Daten aus dem Store entfernen, falls vorhanden
          this.data[tableName] = this.data[tableName].filter((record) => {
            return !filters.some(
              (filter) => filter.column === pk && record[pk] === filter.value
            );
          });
    
          return true;
        })
        .catch((error) => {
          console.error(`[${tableName}] ⛔️ Fehler beim Löschen:`, error);
          throw error;
        });
    },
    previewDeleteData(tableName, filters = []) {
      let query = supabase.from(tableName).select("*");
    
      // Anwenden der Filter auf die Vorschau-Abfrage
      filters.forEach((filter) => {
        const { method, column, operator, value } = filter;
    
        if (method === 'eq') {
          query = query.eq(column, value);
        } else if (method === 'not') {
          query = query.not(column, operator, value);
        } else if (method === 'in') {
          query = query.in(column, value);
        }
        // Hier können weitere Filtermethoden hinzugefügt werden
      });
    
      return query
        .then(({ data, error }) => {
          if (error) {
            throw error;
          }
    
          console.log(`[${tableName}] 🔍 Vorschau der betroffenen Datensätze:`, data);
          return data; // Rückgabe der betroffenen Datensätze (Dry-Run)
        })
        .catch((error) => {
          console.error(`[${tableName}] ⛔️ Fehler bei der Vorschau:`, error);
          throw error;
        });
    },
  },
  getters: {
    formatTableData(state) {
      return (table, key) => {
        // Wenn die Tabelle nicht vorhanden ist, leeres Objekt zurückgeben
        if (!state.data[table]) return {};
        return state.data[table].reduce((acc, item) => {
          acc[item[key]] = item;
          return acc;
        }, {});
      }
    },
    formatRabzug(state) {
      const rows = state.data.tbl_rau_rabzug;
      if (!rows) return [];
      const holzarten = [...new Set(rows.map((item) => item.holzart))].sort();
      const result = [];
      holzarten.forEach((ha) => {
        const children = [];
        rows.forEach((d) => {
          if (d.holzart === ha) {
            children.push({
              key: ha + d.rabzug_mittendm,
              data: {
                rabzug_mittendm: d.rabzug_mittendm,
                rabzug_cm: d.rabzug_cm,
              },
            });
          }
        });
        result.push({
          key: ha,
          data: { holzart: ha },
          children: children,
        });
      });
      return result;
    },
  },
  persist: {
    enabled: true,
  },
});
