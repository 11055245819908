import "primevue/resources/themes/saga-orange/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/layout.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { createI18n } from "vue-i18n";

import { createApp, reactive } from "vue";

import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import FullCalendar from "primevue/fullcalendar";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Galleria from "primevue/galleria";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import SplitButton from "primevue/splitbutton";
import Spinner from "primevue/progressspinner";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import TabMenu from "primevue/tabmenu";
import Tag from "primevue/tag";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Timeline from "primevue/timeline";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import Tree from "primevue/tree";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";

// Leaflet
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const app = createApp(App);

//Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  app,
  dsn: "https://cad1cb5d62c640a19f10468ea104c108@o541819.ingest.sentry.io/5661866",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  logErrors: true,
});

app.config.globalProperties.$appState = reactive({ inputStyle: "outlined" });

app.use(PrimeVue, {
  locale: {
    startsWith: "startet mit",
    contains: "enthält",
    notContains: "enthält nicht",
    endsWith: "endet mit",
    equals: "entspricht",
    notEquals: "entspricht nicht",
    noFilter: "kein Filter",
    accept: "Ja",
    reject: "Nein",
    choose: "Auswahl",
    upload: "Hochladen",
    cancel: "Abbrechen",
    firstDayOfWeek: 1,
    dayNames: [
      "Sontag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
    today: "Heute",
    clear: "Löschen",
    dateFormat: "dd.mm.yy",
    weekHeader: "Wk",
    weak: "Schwach",
    medium: "Mittel",
    strong: "Stark",
    passwordPrompt: "Passwort eingeben",
  },
});

// i18n
import { languages } from "./locales/index.js";
import { defaultLocale } from "./locales/index.js";
import { numberFormats } from "./locales/index.js";
const messages = Object.assign(languages);
const i18n = createI18n({
  locale: defaultLocale,
  numberFormats: numberFormats,

  fallbackLocale: {
    default: ["de-CH", "en"],
  },
  messages,
});

app.use(i18n);

app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(store);

app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("badge", BadgeDirective);

app.config.globalProperties.$http = axios;

const token = localStorage.getItem("token");
if (token) {
  app.config.globalProperties.$http.defaults.headers.common["Authorization"] =
    token;
}

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

//pivot table
import VuePivottable from "vue-pivottable";
import "vue-pivottable/dist/vue-pivottable.css";
app.use(VuePivottable);

app.config.productionTip = false;

app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("AutoComplete", AutoComplete);
app.component("Avatar", Avatar);
app.component("AvatarGroup", AvatarGroup);
app.component("Badge", Badge);
app.component("Breadcrumb", Breadcrumb);
app.component("Button", Button);
app.component("Calendar", Calendar);
app.component("Card", Card);
app.component("Carousel", Carousel);
app.component("Chart", Chart);
app.component("Checkbox", Checkbox);
app.component("Chip", Chip);
app.component("Chips", Chips);
app.component("ColorPicker", ColorPicker);
app.component("Column", Column);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ConfirmPopup", ConfirmPopup);
app.component("ContextMenu", ContextMenu);
app.component("DataTable", DataTable);
app.component("DataView", DataView);
app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("Dialog", Dialog);
app.component("Divider", Divider);
app.component("Dropdown", Dropdown);
app.component("Fieldset", Fieldset);
app.component("FileUpload", FileUpload);
app.component("FullCalendar", FullCalendar);
app.component("InlineMessage", InlineMessage);
app.component("Inplace", Inplace);
app.component("InputMask", InputMask);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("InputText", InputText);
app.component("Galleria", Galleria);
app.component("Knob", Knob);
app.component("Listbox", Listbox);
app.component("MegaMenu", MegaMenu);
app.component("Menu", Menu);
app.component("Menubar", Menubar);
app.component("Message", Message);
app.component("MultiSelect", MultiSelect);
app.component("OrderList", OrderList);
app.component("OrganizationChart", OrganizationChart);
app.component("OverlayPanel", OverlayPanel);
app.component("Paginator", Paginator);
app.component("Panel", Panel);
app.component("PanelMenu", PanelMenu);
app.component("Password", Password);
app.component("PickList", PickList);
app.component("ProgressBar", ProgressBar);
app.component("RadioButton", RadioButton);
app.component("Rating", Rating);
app.component("SelectButton", SelectButton);
app.component("ScrollPanel", ScrollPanel);
app.component("ScrollTop", ScrollTop);
app.component("Slider", Slider);
app.component("Sidebar", Sidebar);
app.component("Spinner", Spinner);
app.component("Skeleton", Skeleton);
app.component("SplitButton", SplitButton);
app.component("Splitter", Splitter);
app.component("SplitterPanel", SplitterPanel);
app.component("Steps", Steps);
app.component("TabMenu", TabMenu);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Tag", Tag);
app.component("Textarea", Textarea);
app.component("TieredMenu", TieredMenu);
app.component("Timeline", Timeline);
app.component("Toast", Toast);
app.component("Toolbar", Toolbar);
app.component("ToggleButton", ToggleButton);
app.component("Tree", Tree);
app.component("TreeTable", TreeTable);
app.component("TriStateCheckbox", TriStateCheckbox);

app.mount("#app");

app.config.globalProperties.$http.interceptors.response.use(
  (response) => {
    if (response.status === 404) {
      alert("You are not authorized");
    }
    if (
      response?.status === 200 &&
      response?.data?.auth === false &&
      response?.config?.url !== process.env.VUE_APP_API_URL + "/logout" &&
      response?.config?.url !== process.env.VUE_APP_API_URL + "/login"
    ) {
      store
        .dispatch("logout", {})
        .then(() => router.push("/pages/login"))
        .catch((err) => console.log(err));
    }
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 ||
      (error?.code === "ERR_NETWORK" &&
        error?.config?.url !== process.env.VUE_APP_API_URL + "/logout") ||
      (error?.response?.status === 400 &&
        error?.response?.data === "Auth session missing!")
    ) {
      store
        .dispatch("logout", {})
        .catch((err) => console.log(err))
        .finally(() => {
          router.push("/pages/login");
        });
    }
    if (error?.response?.status === 404) {
      console.log("Abfrage konnte nicht bearbeitet werden");
    }
    if (error?.response && error?.response?.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

// #TODO: Auskommentieren, bei legacy code

app.config.globalProperties.$http.interceptors.request.use((config) => {
  if (config.url.includes(process.env.VUE_APP_API_URL)) {
    config.withCredentials = true;
  }
  return config;
});
